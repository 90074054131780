
.b-faq__container{
  color: var(--color-light);
}

.b-faq__faq{
  background-color: var(--color-primary);
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  @include in_editor() {
    background-color: $gray-700;
  }
}

.b-faq__question {
  position: relative;
  font-size: 1rem;
  font-weight: 600;

  @include media-breakpoint-down(md) {
    padding-right: 1.5rem;
  }

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: '\f078';
    font-family: $font-awesome;
    font-size: 1rem;
    font-weight: 300;
    position: absolute;
    right: 0;
    top: 50%;
    transform-origin: center;
    transform: translateY(-50%);
    transition: transform $transition-default;

    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }

  &.is-open {
    &:after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.b-faq__answer {
  font-size: 1rem;
  display: none;
  margin-top: 0.5rem;

  a{
    color: var(--color-light);
    text-decoration: underline;
  }
}
