

.t-single__subtitle{
  font-size: 1.375rem;
  line-height: 1.75rem;
  max-width: 740px;

  &.wide{
    max-width: 100%;
  }
}
.t-single__title{
  font-size: 2.25rem;
  margin-top: 0.3rem;
  max-width: 740px;

  &.wide{
    max-width: 100%;
  }
}

.t-single__date{
  font-size: 0.88rem;
  display: block;
  margin: 2rem 0;
}

.t-single__image{
  width:100%;
  height: auto;
  max-width: 740px;
  display: block;

  &.wide{
    max-width: 100%;
  }

  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}

.t-single__breadcrumbs{
  font-size: 0.77rem;

  .fa-chevron-right{
    font-size: 0.6rem;
    margin-right: 0.5rem;
  }
  > * {
    margin-right: 0.5rem;

  }

  a, button {
    text-decoration: underline;
  }

  svg{
    width: 0.667rem;
    height: auto;
  }
}

.t-single__event-finished{
  padding: 0.889rem 1.333rem;
  background-color: rgba(28,28,27,0.06);
  border-radius: $border-radius;
  color: var(--color-secondary);
  font-style: italic;
  margin-bottom: 1.333rem;
}

.t-single__content{
  max-width: 741px;
  a.c-button:not(.c-button-custom){
    @extend .c-button;
  }
  a.c-button--ghost:not(.c-button-custom){
    @extend .c-button--ghost;
  }
  a:not(.c-button-custom){
    color: var(--color-primary);
  }


  h2{
    font-size: 1.66rem;
  }

  h3{
    font-size: 1.33rem;
  }

  h4{
    font-size: 1.11rem;
  }
  h5{
    font-size: 1rem;
  }

  .container, .container-small, .row, .col-12{
    padding: 0;
  }
  &.wide{
    max-width: 100%;
  }
}

.t-single__info-item{
  margin: 0.6rem 0;
  i{
    width: 20px;
    margin-right: 0.5rem;
    text-align: center;
  }
}

.t-single__info-item-label{
  display: inline-block;
  font-weight: 500;
  min-width: 110px;
}

.t-single__info-block{
  border-radius: $border-radius;
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
  width: 100%;
  background-color: var(--color-light);
  padding: 1.25rem;
  margin-bottom: 1.5rem;
}
.t-single__info-block-label{
  display: block;
  text-transform: uppercase;
  font-size: 0.625rem;
  color: var(--color-grey-5);
}


.t-single__content .b-wp-core-paragraph { // voor klassieke editor waarbij de headings onderdeel uitmaken van een blok
  h2,h3,h4,h5 {
    font-size: 1.11rem;
    margin-top: 15px;
    margin-bottom: 10px;
  }
}


.t-single__logo-container{
  height: 162px;
  width: 162px;
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem;
  overflow: hidden;
  margin-left: auto;

  img{
    max-width: 100%;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 1rem;
    margin-right: auto;
  }
}

.t-single__disclaimer {
  max-width: 450px;
  float: right;
  background-color: var(--color-grey-1);
  font-size: 12px;
  color: var(--color-grey-5);
  padding: 0.4rem;
}

.t-single__disclaimer p {
  margin-bottom: 0;
}

.t-single__disclaimer a {
  text-decoration: underline;
}
