
.c-information-menu {  
  display: block;  
  box-shadow: var(--shadow-lighter);  
  padding: 1.333rem;
  border-radius: 4px;
}

.c-information-menu__title {
  font-size: 1.111rem;
}

.c-information-menu__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  line-height: 1.2;  
}
 
.c-information-menu__item {
  padding-top: .55rem;
  padding-bottom: .8rem;
  padding-right: 20px;
  border-bottom: 1px solid var(--color-grey-3);
  position: relative;
}

.c-information-menu__link--active {
  color: var(--color-dark)!important;  
}

.c-information-menu__submenu {
  list-style-type: none;
  padding: 0 0 0 .7888rem;
  margin: .5rem 0 0 0;
  border-left: 1px solid var(--color-grey-3);
  font-size: .7778rem;
  display: none;
}

.c-information-menu__submenu-item {
  padding-bottom: .5rem;  
}

.c-information-menu__submenu-toggle {
  padding: 0;
  border: 0;
  color: var(--color-primary);
  background: none;
  position: absolute;
  right: 0; 
  top: 10px;
  svg path {
    fill: var(--color-primary);
  } 
}

.c-information-menu__item.c-information-menu__item--submenu-active {
  .c-information-menu__submenu {
    display: block;
  }
  .c-information-menu__submenu-toggle {
    transform: rotate(.5turn);    
  }
  
}
