/*
 * Base styling for header elements, default p and other base html elements
 */

body {
  font-size: $font-size-body; // Base font-size 18px
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

small {
  font-size: 0.77rem;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
}

h1 {
  font-size: 2rem;

  @include media-breakpoint-up(md) {
    font-size: 3rem
  }
}

h2 {
  font-size: 1.5rem;

  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
}

h3 {
  font-size: 1.125rem;

  @include media-breakpoint-up(md) {
    font-size: 1.375rem;
  }
}

h4 {
  font-size: 1.125rem;

  @include media-breakpoint-up(md) {
    font-size: 1.25rem;
  }
}

