.b-wp-core-list{
  ol, ul {
    list-style-type: none;
    margin-left: -1rem;
    position: relative;
  }

  ol > li {
    counter-increment: customlistcounter;
    margin-bottom: 1rem;
  }

  ol > li:before {
    content: counter(customlistcounter) ") ";
    float: left;
    width: 1.5rem;
  }

  ol:first-child {
    counter-reset: customlistcounter;
  }


  ul > li{
    margin-bottom: 1rem;
    padding-left: 1rem;
  }

  ul > li:before {
    content: '•';
    position: absolute;
    left: 1.5rem;
  }
}

.b-wp-core-paragraph + .b-wp-core-list{
  margin-top: -0.8rem;
}
