.b-wp-core-table{
  border-radius: 4px 4px 0 0;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    overflow-x: scroll;
  }

  thead{
    background-color: var(--color-primary);
    color: var(--color-light);
    border-bottom: none;

    th{
      font-weight: 600;
      @include media-breakpoint-down(md) {
        min-width: 150px;
      }
    }
  }

  tbody, tfoot{
    font-size: 0.889rem;
    border-top: none;
  }
  .wp-block-table.is-style-stripes{
    border-bottom: none;
  }

  .wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
    background-color: var(--color-light);
  }
  .wp-block-table.is-style-stripes tbody tr:nth-child(even) {
    background-color: var(--color-tertiary-12);
  }

  figcaption{
    text-align: left;
    font-style: italic;
    padding-top: 0.5rem;
    padding-bottom: 0.2rem;
  }
}
