
.wp-block-video{
  position: relative;
  width: 100%;
  video{
    width: 100%;
  }

  &.paused{
    cursor: pointer;
    &:after{
      background-color: var(--color-primary);
      border-radius: 100%;
      width: 100px;
      height: 100px;
      content: '\f04b';
      color: var(--color-light);
      position: absolute;
      text-align: center;
      line-height: 100px;
      top: 50%;
      left: 50%;
      font-weight: bold;
      font-size: 40px;
      font-family: $font-awesome;
      padding-left: 10px;
      transform: translateY(-50%) translateX(-50%);
    }
  }
  figcaption{
    text-align: left;
    color: rgba(0,0,0,0.65);
    font-size: 14px;
    font-style: italic;
  }

}

.b-wp-core-embed-youtube, .b-wp-core-embed-vimeo{
  figcaption{
    text-align: left;
    color: rgba(0,0,0,0.65);
    font-size: 14px;
    font-style: italic;
  }
}

.wp-block-embed-vimeo{
  .wp-block-embed__wrapper{
    height: 460px;
    width: 100%;

    @include media-breakpoint-down(xs) {
      height: 300px;
    }
  }
}
