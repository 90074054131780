
.b-wp-core-image{
  position: relative;
  img{
    max-width: 100%;
    height: auto;
  }

  figcaption{
    text-align: left;
    color: rgba(0,0,0,0.65);
    font-size: 14px;
    font-style: italic;

  }

  &.has_copyright{
    cursor: pointer;
    &:hover{
      .c-copyright__content{
        display: block;
      }
    }
  }
}