.c-sponsored-card__content {
  min-height: 2rem;
  padding: 1.11rem 1.11rem 15px;

  &:hover,
  &:focus {
    text-decoration: none !important;
  }
}


.c-sponsored-card__company-logo {
  max-width: 100%;

  img {
    object-fit: contain;
    max-height: 24px;
    max-width: 100%;
  }
}

.c-sponsored-card__company-name {
  color: var(--color-primary);
  font-size: 0.8125rem;
  line-height: 0.990625rem;
  margin-left: 0.75rem;
  font-weight: $font-weights-medium;

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--color-primary);
  }
}

.c-sponsored-card__title {
  font-size: 1rem;
  font-weight: $font-weights-semi-bold;
  letter-spacing: 0;
  line-height: 1.1875rem;
  margin-bottom: 8px;
}

.c-sponsored-card__label{
  position: absolute;
  bottom:8px;
  left:8px;
  padding: 2px 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: var(--color-light);
  color: var(--color-primary);
  font-weight: $font-weights-medium;
  font-size: 10px;
  line-height: 0.761875rem;
}
.c-sponsored-card__content{
  height:100%;
}

.c-sponsored-card__text{
  margin-bottom: 0.625rem;
}
