.c-button {
  background-color: var(--color-primary);
  border-radius: 4px;
  font-size: 1rem;
  color: var(--color-light);
  transition: $transition-default;
  padding: 0.6rem 1rem;
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  border: 0;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    box-shadow: var(--shadow-default);
    color: var(--color-light);
  }
  &:focus {
    text-decoration: none;
    color: var(--color-light);
    box-shadow: var(--shadow-inset);
    background-color: var(--color-primary-darker);
    outline: none;
  }
  
  svg {
    margin-left: .5rem;
  }

  @include media-breakpoint-up(md) {
    padding: 0.6rem 1.5rem;
  }

  @include in_editor() {
    background-color: $color-visual-editor-block-group;
    color: white;
  }

  &--disabeled{
    opacity: 24%;
  }
}

// Default styling for when there are multiple buttons behind each other
.c-button + .c-button {
  margin-top: 0.5rem;
  @include media-breakpoint-up(md) {
    margin-left: 1rem;
    margin-top: 0;
  }
}


/*---------------------------*
 * MODIFIERS
 *---------------------------*/

.c-button--secondary{
  background-color: var(--color-secondary);
  &:hover {
    box-shadow: var(--shadow-default);
  }
  &:focus {
    box-shadow: var(--shadow-inset);
    background-color: var(--color-secondary-darker);
  }
  @include in_editor() {
    background-color: $color-visual-editor-block-group;
    color: white;
  }
}

.c-button--transparent {
  background-color: transparent;
  color: var(--color-secondary);
  font-weight: 600;
  &:hover {
    color: var(--color-secondary);
    background-color: var(--color-light);
    box-shadow: var(--shadow-default);
  }
  &:focus {
    color: var(--color-secondary);
    box-shadow: var(--shadow-inset);
    background-color: var(--color-grey-1);
  }
}

.c-button--transparent--arrow {
  &:after{
    content: '';
    display: inline-block;
    height: 18px;
    width: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 12px;

    background-color: var(--color-secondary);
    -webkit-mask-image: url("../img/arrow-right-light.svg");
    mask-image: url("../img/arrow-right-light.svg");
    margin-bottom: -4px;
    mask-repeat: no-repeat;

  }
}

.c-button--smaller {
  padding: 0.5rem .8rem;
  i{
    margin-right: 0.4rem;
  }
  @include media-breakpoint-up(md) {
    padding: .5rem 1.6rem;
  }

}
.c-button--fullwidth {
  width: 100%;
  text-align: center;
}

.c-button--link {
  background: none;
  transition: color $transition-default;
  text-transform: none;
  padding: 0;
  border-radius: 0;
  border: none;

  &:hover,
  &:focus {
    background: none;
  }
}

.c-button--link {
  color: var(--color-primary);
  font-weight: 500;
  font-size: 0.77rem;

  &:hover,
  &:focus {
    color: var(--color-dark);

  }

  i {
    font-size: 0.77rem;
  }
}



.c-button--link-normal{
  font-size: 1rem;
}

.c-button--ghost {
  background-color: transparent;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  padding: 0.55rem 1.2rem;
  transition: all $transition-default;

  &:hover{
    color: var(--color-primary);
    box-shadow: var(--shadow-default);
  }
  &:focus, &--active {
    color: var(--color-primary);
    box-shadow: var(--shadow-inset);
    background-color: var(--color-grey-1);
    outline: none;
  }

  &.small{
    padding: 0.3rem 1.2rem;
  }

  &.big{
    height: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: space-evenly;
    font-weight: normal;
    i{
      margin-right: 0.5rem;
    }
    @include media-breakpoint-down(md) {
      height: 40px;
    }
  }
  
  &--disabled {
    opacity: 0.2;
  }
  @include in_editor() {
    border: 1px solid $gray-800;
    color: $color-visual-editor-block-group;
  }
}

.c-button--ghost--secondary {
  background-color: transparent;
  color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  padding: 0.55rem 1.2rem;
  transition: all $transition-default;

  &:hover{
    color: var(--color-secondary);
    box-shadow: var(--shadow-default);
  }
  &:focus, &--active {
    color: var(--color-secondary);
    box-shadow: var(--shadow-inset);
    background-color: var(--color-grey-1);
    outline: none;
  }

  &.small{
    padding: 0.3rem 1.2rem;
  }

  &--disabled {
    opacity: 0.2;
  }
  @include in_editor() {
    border: 1px solid $gray-800;
    color: $gray-800;
  }
}

.c-button--no-border{
  border: none;
}


.c-button--category{
  padding: 0.5rem 1rem;
  font-size: 0.77rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.c-button--search {
  height: 60px;
  font-weight: 600;
  &:hover, &:focus {
    font-weight: 600;
  }
  box-shadow: var(--shadow-lighter);
}

.c-button--icon {
  padding: 0.8rem 1.5rem;
}

.c-button--social {
  font-size: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 0.4rem;

  @include media-breakpoint-down(md) {
    font-size: 1.5rem;
    padding: 0 0.2rem 0 0.5rem;
  }
}

.c-button--square {
  width: 48px;
  height: 48px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  @include media-breakpoint-down(md) {
    width: 40px;
    height: 40px;
  }
  svg {
    margin-left: 0;
  }
}


.c-button--nohover, .c-button--gdisabled {
  pointer-events: none;
}

.c-button__skip{
  position: absolute;
  top: -50px;
  left: 0;
  background-color: var(--color-primary);
  color: var(--color-light);
  padding: 0.5rem 1rem;
  z-index: 100;
}
.c-button__skip:focus{
  top: 0;
}


.c-button__container-options{
  @include media-breakpoint-down(md) {
    margin-top: 1.5rem;
    display: flex;
    justify-content: flex-start;
    width: 100%;
      .c-button{
        display: inline-flex;
        margin-top: 0;
        margin-right: 0.5rem;
      }

  }

  .c-button{
    img{
      max-height: 1rem;
      margin-right: 0.4rem;
    }
  }


}

.c-button__container-options-favorite{
  position: relative;
  display: inline-block;
  margin-left: 1rem;
  @include media-breakpoint-down(md) {
    margin-left: 0;
  }
}
.c-button__container-options-message{
  position: absolute;
  bottom: -2.3rem;
  right: 0;
  height: 2rem;
  width: 220px;
  text-align: center;
  background-color: var(--color-light);
  box-shadow: var(--shadow-lighter);
  padding: 0.3rem;
  z-index: 2;
  font-size: 0.875rem;
  display: none;

  &.show{
    display: block;
  }
  @include media-breakpoint-down(md) {
    left: 0;
  }
}

.c-button--transparent-dotted-arrow {
  background-color: transparent;
  color: var(--color-primary);
  position: relative;
  padding: 0 1.875rem 0 0;

  &:after {
    content: '';
    display: block;
    height: 1.25rem;
    width: 1.25rem;
    background-image: url("../img/arrow-right.svg");
    margin-left: 0.5625rem;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:hover,
  &:focus {
    box-shadow: none;
    background-color: transparent;
    color: var(--color-primary);
    cursor: pointer;
    padding-right: 2.0625rem;
  }
}

.c-button--ghost-white {
  background-color: transparent;
  border: 1px solid white;
  color: white;

  @include in_editor() {
    border: 1px solid $gray-800;
    color: $color-visual-editor-block-group;
  }
}
