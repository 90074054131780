
.t-single--person{
  @include media-breakpoint-down(sm) {
    .c-social-share__container{
      margin-top: -2rem;
    }
  }
}

.t-person-single__top-right{
  height: 162px;
  width: 162px;
  background-color: var(--color-tertiary-36);
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
  @include media-breakpoint-down(sm) {
    margin-top: 1rem;
  }
}

.t-person-single__top-left{
  max-width: 665px;
}

.t-person-single__info-card{
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);

}
.t-person-single__info-card-details{
  font-size: 0.875rem;
}

.t-person-single__info-card-icon{
  color: var(--color-primary);
  display: inline-block;
  min-width: 25px;
}

.t-person-single__info-card-socials{
}

.t-person-single__info-card-social{
  background-color: var(--color-primary);
  color: white;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: $transition-base;
  &:hover{
    background-color: white;
    color: var(--color-primary);
    transition: $transition-base;
    border: 1px solid var(--color-primary);
    text-decoration: none;
  }
}

.t-person-single__categories {
  font-size: 0.83rem;
  line-height: 1.3rem;
  color: #848480;
  margin-top: 1.2rem;
  max-width: 90%;

  i {
    margin-right: 8px;
    margin-left: 1px;
    font-size: 13px;
  }

  &--person-card {
    margin-bottom: 0;
  }
}

.t-person-single__categories-comma:last-child {
  display: none;
}



.t-person-single__biography-button {
  white-space: nowrap;
  margin-top: -5px !important;
  margin-bottom: 6px;
}