
.c-card-highlighted {
  @extend .d-flex, .flex-row-reverse, .justify-content-end, .justify-content-lg-between;

  position: relative;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: var(--shadow-lighter);
  transition: $transition-default;
  height: 280px;
  text-decoration: none!important;

  &:hover, :active{
    color: var(--color-dark);
    text-decoration: none;
    .c-card-highlighted__title {
      color: var(--color-secondary);
      text-decoration: underline;
    }
    .c-button{
      color: var(--color-light);
    }
  }

  &:hover{
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.16);
  }

  &:active {
    background: var(--color-grey-2);
  }


  @include media-breakpoint-down(md) {
    height: auto;
    flex-direction: column-reverse!important;
  }
}


.c-card-highlighted__content{
  min-height: 130px;
  width: 47%;
  padding: 1.55rem 1.55rem;

  > :last-child {
    margin-bottom: 0
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    max-width: none;
  }

}

.c-card-highlighted__date{
  font-size: 0.6rem;
  color: var(--color-grey-5);
  letter-spacing: 1px;
}
.c-card-highlighted__title{
  margin-top: 0.5rem;
  font-size: 1rem;
  line-height: 22px
}

.c-card-highlighted__image{
  width: 53%;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
    img {
      height: 120px;
    }
  }
}

.c-card-highlighted__icon{
  width: 53%;
  background-color: var(--color-tertiary-48);
  color: var(--color-primary);
  font-size: 4.5rem;
  text-align: center;
  padding: 2rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-down(md) {
    width: 100%;
    height: 120px;
    padding: 1rem;
    font-size: 2.5rem;
    flex-shrink: 0;
  }

  svg{
    width: 2.9rem;
  }
}

.c-card-highlighted__excerpt {
  font-size: 0.889rem;
}


.c-card-highlighted__badge {
  position: absolute;
  top: .6rem; left: .6rem;
  border-radius: 4px;
  background-color: var(--color-secondary);
  color: var(--color-light);
  text-transform: uppercase;
  font-size: 0.722rem;
  font-weight: 600;
  padding: .3em .6em;
}


.c-card-highlighted__logo{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: 53%;
  border-top: 1px solid var(--color-grey-2);
  img{
    max-width: 100%;
    max-height: 100px;
    height: auto;
    display: block;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    height: 120px;
    padding: 2rem 1rem;
  }

}
