
.c-item-card {
  @extend .d-flex, .flex-row-reverse, .flex-lg-column;

  position: relative;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: var(--shadow-lighter);
  transition: $transition-default;
  height: 100%;
  text-decoration: none!important;

  &:hover,:active{
    color: var(--color-dark);
    text-decoration: none;
    .c-item-card__title {
      color: var(--color-secondary);
      text-decoration: underline;
    }
    .c-item-card__icon {
      color: var(--color-primary);
    }
    .c-item-card__title--nolink{
      text-decoration: none;
      color:var(--color-dark);
    }
  }

  &:hover{
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.16);
  }

  &:active {
    background: var(--color-grey-2);
  }
  &.c-item-card--grid__grid-row{
    @extend .flex-lg-row;
    height: 136px;
    @include media-breakpoint-down(md) {
      height: 100%;
    }
  }
}

.c-item-card--mobile-row{
  @extend .justify-content-end, .justify-content-lg-between;
}

.c-item-card__content{
  min-height: 130px;
  padding: 1.11rem 1.11rem;

  > :last-child {
    margin-bottom: 0
  }

  @include media-breakpoint-down(md) {
    max-width: calc(100% - 110px);

    &.wider{
      max-width: 100%;
      width: 100%;
    }
  }
}

.c-item-card__content--small{
  min-height: 2rem;
}

.c-item-card__remove{
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  padding: 0.5rem;
  color: var(--color-grey-4);

  &:hover{
    color: var(--color-dark);
  }
}

.c-item-card__favorite{
  font-size: 0.75rem;
  margin-top: 0.5rem;
  i{
    margin-right: 0.4rem;
  }
}

.c-item-card--search {
  .c-item-card__content {
    padding: 1rem 1rem .778rem 1rem;
  }
}



.c-item-card__content--blog{
  padding: 1.55rem 1.55rem 0.8rem;
  min-height: 188px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.c-item-card__date, .c-item-card__posttype {
  font-size: 0.6rem;
  color: var(--color-grey-5);
  letter-spacing: 1px;
  text-transform: uppercase;
}

.c-item-card__posttype--collection{
  color: var(--color-primary);
  font-weight: bold;
}

.c-item-card__date--with-icon {
  font-size: .778rem;
}

.c-item-card__title{
  margin-top: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 22px;
  font-weight: 600;
}

.c-item-card__title--l{
  font-size: 1.375rem;
  line-height: 1.5rem
}

.c-item-card__title--xl{
  font-size: 1.5rem;
  line-height: 2rem
}

.c-item-card__image{
  img{
    width: 100%;
    object-fit: cover;
    object-position: center;
    @include media-breakpoint-down(md) {
      width: 115px;
      height: 100%;
    }
  }
  &.landscape-big{
    img{
      height: 200px;

      @include media-breakpoint-down(md) {
        height: 100%;
      }
    }
  }
  &.portrait{
    img{
      height: 405px;

      @include media-breakpoint-down(md) {
        height: 100%;
      }
    }
  }
  &.row-image{
    min-height: 80px;
    width: 115px;
    display: block;
    margin-left: -1rem;
    margin-right: 1rem;
    flex-shrink: 0;
    img{
      max-height: 87px;
    }
  }

  &.main{
    img{
      min-height: 530px;

    }
  }

  &.side{
    img{
      min-height: 197px;
    }
  }

  &.grid-row{
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    display: block;
    img{
      width: 120px;
      height: 120px;
    }
  }
}

.c-items-card__image-grid {
  height: 100%;

  img {
    height: 80%;
    object-fit: contain;
    margin-top: 7px;
  }
}

.c-items-card__image-contain img {
  object-fit: contain;
}

.c-item-card__summary {
  font-size: .778rem;
  line-height: 1.4;
}

.c-item-card__authors{
  font-style: italic;
  opacity: 0.6;
  font-size: 0.778rem;
}

.c-item-card__authors--dark{
  font-size: 0.875rem;
  letter-spacing: -0.1px;
  line-height: 1.125rem;
}

.c-item-card__icon{
  background-color: var(--color-tertiary-48);
  min-height: 147px;
  color: var(--color-primary);
  font-size: 2.9rem;
  text-align: center;
  padding: 2rem 2.5rem;


  @include media-breakpoint-down(md) {
    padding: 1rem;
    font-size: 2rem;
    line-height: 110px;
  }

  svg{
    width: 2.9rem;
  }
  &.landscape-big{
    min-height: 200px;
    font-size: 3.5rem;
    padding: 3rem 2.5rem;
    align-items: center;
  }

  &.portrait{
    min-height: 405px;

    @include media-breakpoint-down(md) {
      min-height: 147px;
    }
  }

  &.row-image{
    min-height: 84px;
    font-size: 2.5rem;
    width: 115px;
    display: flex;
    margin-left: -1rem;
    margin-right: 1rem;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
  }

  &.main{
    min-height: 530px;
    @include media-breakpoint-down(md) {
      height: 100%;
      min-height: 140px;
    }
  }

  &.side{
    min-height: 197px;
    @include media-breakpoint-down(md) {
      height: 100%;
      min-height: 120px;
    }
  }
  &.landscape{
    min-height:auto;
    img{
      max-height:70px;
    }
  }
}

.c-item-card__items{
  color: var(--color-grey-5);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.556rem;
}

.c-item-arrow{
  margin-left: auto;
  padding-bottom: 1rem;
  padding-right: 1rem;
}


.c-item-card__info-with-icon{
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  i{
    width: 1.5rem;
  }
}


.c-item-card__logo{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  height: 144px;
  border-top: 1px solid var(--color-grey-2);
  img{
    max-width: 100%;
    max-height: 100px;
    height: auto;
    display: block;
  }

  @include media-breakpoint-down(md) {
    height: auto;
    width: 110px;
  }

  &.portrait{
    min-height: 405px;

    @include media-breakpoint-down(md) {
      min-height: 144px;
    }
  }
}

.c-item-card--grid{
  &.nude{
    box-shadow:none;
    border-radius: unset;
    .c-item-card__content{
      padding: 1rem 0 0;
      min-height: 110px;
    }

    &:not(.c-item-card--grid__main){
      box-shadow: inset 0 -1px 0 0 var(--color-tertiary-12);
      padding-bottom: 1rem;
    }
  }


}

.c-item-card--grid__grid-row{
  &.nude{
    .c-item-card__content{
      padding: 0 1rem;

      @include media-breakpoint-down(md) {
        padding: 0rem;
      }
    }
  }
  .c-card-overlay__icon{
    width: 32px;
    height: 32px;
    font-size: 1rem;
  }

  .c-item-card--grid__image{
    height: 120px;

    @include media-breakpoint-down(md) {
      height: 100%;
    }
  }
}

.c-item-card--grid__image {
  position: relative;
}

.c-item-card__title--nolink {
  text-decoration: none;
  color: var(--color-dark);
}
