.b-wp-gravityforms-form {
  @extend .container;

}

input[type=text],
input[type=search],
input[type=email],
input[type=url],
input[type=password],
textarea, select{

  width: 100%;
  background-color: var(--color-light);
  height: 48px;
  font-size: 16px;
  color: var(--color-dark);
  line-height: 1.5;

  padding: 0.5rem 1rem;
  
  border-radius: 4px;
  border: 1px solid var(--color-grey-3);

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-grey-3);
    opacity: 1; /* Firefox */
  }

  &:focus {
    border: 1px solid var(--color-dark);
    box-shadow: var(--shadow-lighter);
    outline: none;
    color: var(--color-dark);
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--color-dark);
    }
  }

  &.input--alt--state{
    box-shadow: var(--shadow-lighter);

    &:hover {
      box-shadow: var(--shadow-default);
    }
    &:focus {
      color: var(--color-grey-4);
      box-shadow: var(--shadow-lighter);
      &::placeholder {
        color: var(--color-grey-4);
      }
    }
  }
}

textarea{
  height: 145px;
}

input[type=submit],button[type=submit] {
  @extend .c-button;
}



input[type=radio], input[type=checkbox] {
  display: none!important;
  @include in_editor() {
    display: inline-block!important;
  }
}

.acf-button-group{
  input[type=radio], input[type=checkbox] {
    @include in_editor() {
      display: none!important;
    }
  }
}

input[type=radio] + label, input[type=checkbox] + label {
  position: relative;
  cursor: pointer;
  margin: 0 0 1rem 1.4rem!important;
}

input[type=radio] + label:before, input[type=checkbox] + label:before {
  content: '';
  display:inline-block;
  width:18px;
  height:18px;
  margin: 0.7rem;
  vertical-align:middle;
  cursor:pointer;
  border-radius:  50%;
  background-color: white;
  border: 1px solid var(--color-grey-3);
  position: absolute;
  top:-0.5rem;
  left:-2.1rem;
}

input[type=checkbox] + label:before {
  border-radius: 4px;
}

input[type=radio]:checked + label,input[type=checkbox]:checked + label {
  position: relative;
  width: 100%;
  background-color: var(--color-tertiary-24);

  &:after {
    content: "\f00c";
    font-family: $font-awesome;
    color: white;
    position: absolute;
    display: inline-block;
    text-align: center;
    cursor:pointer;
    left: -1.2rem;
    top: 0.36rem;
    font-size: 0.7rem;
    font-weight: bold;
  }

  &:before {
    background-color: var(--color-primary);
  }
}


.c-form__group {
  padding: 8px 0 16px;
}
.c-form__check {
  padding: 8px 0 16px;
}
.c-form__check__checkbox{
  margin-right: 12px;
}

.c-form__field__label,.c-form__check__label {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-dark);
  letter-spacing: 1px;
}

.c-form__link{
  font-size: 12px;
  color: var(--color-grey-5);
  text-decoration: underline;
}

.c-form__validation-error{
  display: block;
  font-size: 12px;
  color: $color-form-validation-error;
}

.c-form__file-upload{
  @extend .c-button;
  cursor: pointer;
  margin-top: 0.5rem;
  i{
    padding-right: 0.5rem;
  }

  .gform_fileupload_rules{
    display: none;
  }
}
.c-form__file-upload-text{
  font-size: 0.8125rem;
  font-style: italic;
  color: var(--color-grey-5);
}


// Gravity forms specific

.gform_wrapper {
  padding: 2rem;
  background-color: var(--color-tertiary-12);
  border-radius: $border-radius;
  margin-bottom: 2rem;

  .validation_error {
    font-size: 12px;
    background-color: $color-form-validation-error;
    color: $color-form-validation-error-text;
    padding: 12px;
  }
}

.gform_title{
  font-size: 2rem !important;
}

.gform_description{
  font-size: 1.0625rem;
  letter-spacing: -0.1px;
}

.gform_fields, .gfield_checkbox, .gfield_radio {
  @extend .list-unstyled;
  margin: 0;
}

.gfield {
  @extend .c-form__group;

  &.gform_hidden {
    padding: 0;
  }
}


.gfield_label {
  @extend .c-form__field__label;
  font-size: 1rem;
}

.gfield_description {
  &.validation_message {
    font-size: 12px;
    color: $color-form-validation-error;
  }
}

.ginput_container_radio{
  margin-top: 0.5rem;
}

.gf_progressbar_title{
  font-size: 1rem !important;
  margin-top: 1.5rem;
}
.gf_progressbar{
  width: 100%;
  border-radius: $border-radius;
  background-color: #F2F2F2;
  height: 8px;
  margin-bottom: 1.5rem;
}

.gf_progressbar_percentage{
  height: 100%;
  background-color: var(--color-primary);
  border-radius: $border-radius;


  span{
    display: none;
  }
}



.ui-datepicker {
  border: 1px solid var(--color-grey-3);
  border-radius: 4px;
  padding: .875rem 1rem;
  line-height: 1.125rem;
  background: var(--color-primary);
  color: white;
  outline: none;
  font-size: .875rem;
  position: absolute;
  top: 0;

  .ui-datepicker-prev, .ui-datepicker-next {
    span {
      position: relative;
      color: transparent;
      padding-bottom: 1rem;
      display: inline-block;
      cursor: pointer;

      &:hover {
        &:after {
          color: var(--color-tertiary);
        }
      }

      &:after {
        font-family: $font-awesome;
        font-size: 1.25rem;
        color: white;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .ui-datepicker-prev {

    span {
      &:after {
        content: '\f177';
      }
    }
  }

  .ui-datepicker-next {

    span {
      &:after {
        content: '\f178';
      }
    }
  }

  .ui-datepicker-title {
    margin-bottom: .5rem;
    display: flex;
  }

  select {
    border: 1px solid var(--color-grey-3);
    padding: 0.5rem 0.8rem;
    margin: 0.2rem;
    line-height: 1.125rem;
    background: var(--color-primary);
    color: white;
    outline: none;
    font-size: .875rem;
  }

  a {
    text-decoration: none;
  }

  .ui-state-default{
    color: white;

    &:hover{
      color: var(--color-tertiary);
    }
  }

  .ui-state-active{
    background-color: white;
    color: var(--color-primary);
    padding: 3px;
  }
}

.ginput_container_date{
  .screen-reader-text{
    visibility: hidden;
  }
}

.ginput_container_fileupload{
  input[type=file]{
   display: none;
  }
}



.field_description_above{
  .gfield_label{
    margin-bottom: 0.5rem;
  }
}

.gsection_title{
  font-size: 1.375rem !important;
  font-weight: 500;
  letter-spacing: 0;
}

.gsection_description{
  font-size: 1.0625rem;
  letter-spacing: -0.1px
}

.gform_page_footer{
  display: flex;
  justify-content: center;
}

.gform_next_button{
  @extend .c-button;
}

.gform_previous_button{
  @extend .c-button--ghost;
  margin-right: 1rem;
  border-radius: $border-radius;
}

.b-wp-gravityforms-form{
  max-width: 600px;
}

.gfield_radio{
  .gfield-choice-input{
    display: none;
  }

  .gchoice{
    input[type=radio]{
      display: none !important;
    }
  }

}
