

.c-information-card{
  position: relative;
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: var(--shadow-lighter);
  transition: $transition-default;
  height: 100%;
  display: block;
  text-decoration: none!important;

  &:hover,:active{
    color: var(--color-dark);
    text-decoration: none;
    .c-information-card__title {
      color: var(--color-secondary);
      text-decoration: underline;
    }
  }

  &:hover{
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.16);
  }

  &:active {
    background: var(--color-grey-2);
  }


}
.c-information-card__top{
  padding: 1.111rem;
  min-height: 110px;
}

.c-information-card__title{
  font-size: 1rem;
  line-height: 22px;
  margin-bottom: 0;
}

.c-information-card__content{
  border-top: 1px solid $border-color;
  font-size: 0.722rem;
  padding: 1.111rem;
}

.c-information-card__category{
  color: var(--color-secondary);
  text-transform: uppercase;
  font-size: 0.611rem;
  font-weight: bold;
}
