
.c-dossier-card{
  border-radius: $border-radius;
  box-shadow: var(--shadow-lighter);
  transition: $transition-default;
  display: block;
  &:hover,:active{
    color: var(--color-dark);
    text-decoration: none;
    .c-item-card__title {
      color: var(--color-secondary);
      text-decoration: underline;
    }
    .c-dossier-card__icon{
      color: var(--color-primary);
    }
  }

  &:hover{
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.16);
  }

  &:active {
    background: var(--color-grey-2);
  }
}
.c-dossier-card__top{
  min-height: 100px;
}
.c-dossier-card__down{
  background-color: var(--color-tertiary-36);
  width: 100%;
  height: 144px;
}



.c-dossier-card__title{
  font-size: 1rem;
}

.c-dossier-card__icon{
  font-size: 2.9rem;
  text-align: center;
  padding: 2rem 2.5rem;
  height: 100%;
}

.c-dossier-card__icon-image {
  max-height: 100%;

  img {
    object-fit: contain;
  }
}



