.c-filter {
  margin-bottom: 1rem;
}

.c-filters{
  position: relative;
  @include media-breakpoint-down(sm) {
    display: none;
    &.is-open {
      display: block;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: var(--color-primary);
        z-index: 10;
        margin: 0;
        padding: 2rem 1rem;
        color: white;
      overflow-y: scroll;
    }
  }
}

.c-filters__close{
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: 1px solid white;
  border-radius: $border-radius;
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.c-filter__heading {
  margin-bottom: .5rem;
}
.c-filter__heading-title {
  font-size: 1.11rem;
  font-weight: 600;
}

.c-filter__content {
  > *:not(:last-child) {
    margin-bottom: .75rem;
  }
}

.c-filter__reset{
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.778rem;
}
.c-filter__selected-filter{
  display: inline-block;
  margin: 0.2rem 0.2rem 0.2rem 0;
  padding: 0.4rem 1rem;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  border-radius: 24px;

  i{
    cursor: pointer;
    margin-right: 0.3rem;
    vertical-align: middle;
  }
  @include media-breakpoint-down(sm) {
    color:var(--color-light);
    border: 1px solid var(--color-light);
  }
}

.c-filter__container{
  border-radius: $border-radius;
  background-color: rgba(0,0,0,0.05);
  padding: 0.5rem 1rem;

  @include media-breakpoint-down(sm) {
    background-color: unset;
  }

}

.c-filter__container-heading{
  position: relative;
  padding: 0.4rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &.is-open {
    i, svg{
      transform: translateY(-50%) rotate(180deg);
    }
  }

}
.c-filters__list{
  //display: none;
  padding: 0.5rem 0;
  max-height: 220px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    background-color: rgba(0,0,0,0.12);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
}

.c-filter__item{
  display: block;
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
  user-select: none;


  &:hover{
    .c-filter__item-checkbox,.c-filter__item-radio{
      background-color: #ccc;
    }
  }
}

.c-filter__item-input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked{
    ~.c-filter__item-checkbox,~.c-filter__item-radio{
      background-color: var(--color-primary);
      @include media-breakpoint-down(sm) {
        background-color: white;
      }
      &:after {
        display: block;
      }
    }
  }
}

.c-filter__item-checkbox,.c-filter__item-radio{
  position: absolute;
  top: 3px;
  left: 0;
  height: 21px;
  width: 21px;
  border: 1px solid var(--color-dark);
  background-color: #eee;
  border-radius: $border-radius;

  &:after {
    content: "\f00c";
    font-family: $font-awesome;
    position: absolute;
    color: var(--color-light);
    display: none;
    text-align: center;
    width: 21px;
    line-height: 21px;
  }

  @include media-breakpoint-down(sm) {
    border-color: white;
    background-color: unset;
    &:after{
      color: var(--color-primary);
    }
  }
}

.c-filter__item-radio{
  border-radius: 100%;
}