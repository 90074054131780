
.c-social-share__container{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-start;
  position: relative;
  margin-right: 1rem;
  width: 50px;
  z-index:2;
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-right: 0;
  }
}
.c-social-share{
  text-align: center;
  border-radius: 4px;
  background-color: var(--color-light);
  box-shadow: var(--shadow-lighter);
  transition:opacity 150ms ease-in;
  opacity:1;
  width: 50px;
  height: auto;



  &.fix{
    position:fixed;
    top: 130px;
  }

  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
  }

}

.c-social-share__button{

  transition: $transition-default;
  display:inline-block;
  position:relative;
  color: var(--color-primary);
}
//
//.t-single--information {
//  .c-social-share__container {
//    margin-right: 0;
//  }
//}
