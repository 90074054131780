.b-hero-cards {
  .container {
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }
}

.b-hero-cards__image-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.b-hero-cards__head {
  position: relative;
  background-color: var(--color-light);
  border-radius: 4px;
  padding: 36px;
  width: 60%;
  min-width: 300px;
  box-shadow: var(--shadow-lighter);

  @include media-breakpoint-up(sm) {
    width: 492px;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 24px;
  }
}

.b-hero-cards__title {
  color: var(--color-primary);
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  @include media-breakpoint-up(sm) {
    font-size: 36px;
    line-height: 36px;
  }
  @include media-breakpoint-up(md) {
    font-size: 48px;
    line-height: 48px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.b-hero-cards__text {
  margin-top: 16px;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-dark);
  @include media-breakpoint-up(sm) {
    font-size: 16px;
    line-height: 22px;
  }
  @include media-breakpoint-up(md) {
    font-size: 18px;
    line-height: 26px;
  }
}

.b-hero-cards__column {
  padding: 15px;
  @include media-breakpoint-up(sm) {
    padding: 30px;
  }
  @include media-breakpoint-up(md) {
    padding: 60px 120px 36px;
  }
}

.b-hero-cards__row {
  margin-top: 86px;
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
  }
}

.b-hero-cards__link a:hover {
  text-decoration: none;

  .b-hero-cards__title {
    text-decoration: underline;
  }
}