.b-wp-core-heading + .b-wp-core-paragraph {
  margin-top: 0;
}

.b-wp-core-paragraph{
  margin: 2rem 0;
  .has-larger-font-size{
    font-size: 1.11rem;
    font-weight: 600;
    line-height: 1.77rem;
  }
}
