.b-text-area{

}

.b-text-area--background-color-mobile{
  @include media-breakpoint-down(sm) {
    background: var(--color-tertiary-24);
  }
}
.b-text-area--background-color{
  background: var(--color-tertiary-24);
  padding: 4rem 1rem 2rem;
  h2, h3{
    color: var(--color-primary);
  }

  @include media-breakpoint-down(sm) {
    padding: 2rem 0 1rem;
    background: transparent;
  }
}

.b-text-area__intro{
    .b-text-area__position{
      text-align: center;
      align-items: center;
    }

  .b-text-area__content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2{
      font-size: 3rem;
    }
    p{
      max-width: 588px;
    }
    @include media-breakpoint-down(sm) {

      p{
        max-width: 100%;
      }
      h2{
        font-size: 1.7rem;
      }
    }
  }

}

.b-text-area__content{
  h2, h3{
    padding-bottom: 0.5rem;
  }
  p{
    line-height: 28px;
  }
}

.b-text-area__position{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.b-text-area__position--center{
  align-items: center;
  text-align: center;
}

.b-text-area__position--right{
  align-items: flex-end;
  text-align: right;
}

.b-text-area__content--50{
  width: 50%;
  p{
    padding-right: 2rem;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.b-text-area__content--70{
  width: 70%;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.b-hero-slider-two + .b-text-area {
  margin-top: 0;

  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
}


