// Global layout styles


img {
  max-width: 100%;
}


// Default link color transition
a {
  color: var(--color-dark);
  transition: color $transition-default;

  &:hover {
    color: var(--color-primary);
  }
  @include in_editor() {
    text-decoration: underline;
  }
}

body{
  &.menu-is-open{
    background-color: rgba(#1C1C1B, 0.6);
    z-index: 1;
    position: fixed;
    overflow: hidden;
    width: 100%;
  }
}

// Add margin for targeted anchor links
[id] {
  scroll-margin-top: calc(#{$header-height} + 2ex);
}


