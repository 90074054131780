@media print {
  html, body {
    height: auto!important;
  }
  body {
    padding: 2rem 2rem;
  }

  .s-header {
    position: relative;
  }

  .s-wrapper {
    padding-top: 0;
  }

  .s-footer, .c-social-share__container, .s-header,
  .s-header__submenu, .b-four-cards, .t-single__print-buttons, .t-single__breadcrumbs, .t-single__archive-button,.b-ad-itempage__multiblock, .b-ad-itempage {
    display: none !important;
  }

  #pdfprint {
    position: relative;
    margin-top: -150px;
  }

  .t-single__content {
    max-width: none !important;
  }

  p,figure, .b-wp-core-block ,.b-wp-core-paragraph, .b-wp-core-block,.b-text-framework, .b-wp-core-image, .b-wp-core-quote,.b-authors ,.b-comments,.b-topics {
    break-inside: avoid!important;
  }
}
