

.c-menu__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  margin: 0;
  padding-left: 0;
  height: 100%;
  @include media-breakpoint-down(md) {
    display: block;
    height: auto;
    width: 100%;
  }
}

.c-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;

}

.c-menu__item {
  //margin: 0 1px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.4rem;
  i{
    margin-right: 0.25rem;
  }
  @include media-breakpoint-down(md) {
    margin: 1.2rem 0;
    font-size: 1.2rem;
  }
  @include media-breakpoint-down(xs) {
    margin: 1rem 0;
    font-size: 1.1rem;
  }

  &.external_link{
    border-left: 1px solid var(--color-grey-3);

    &:after {
      content: '\f08e';
      font-family: $font-awesome;
      display: inline-block;
      margin-left: 0.1rem;
      font-size: 0.9rem;
      font-weight: lighter;
    }
    @include media-breakpoint-down(md) {
      border-left: none;
      //border-top: 1px solid $border-grey;
      //padding-top: 0.6rem;
    }

  }

  &.highlighted_link{
    margin: 0 .2rem;
    padding: 0;
    a {
      background-color: var(--color-secondary);
      color: var(--color-light);
      border-radius: $border-radius;
      line-height: 24px;
      padding: 0 .5rem;
      
      &:hover {
        color: var(--color-light);
        box-shadow: var(--shadow-default);
      }
      &:focus {
        box-shadow: var(--shadow-inset);
        background-color: var(--color-secondary-darker);
      }
    }

    @include media-breakpoint-down(md) {
      background-color: transparent;
      margin: 0.8rem 1rem;
      a{
        background-color: var(--color-secondary);
        color: var(--color-light);
        border-radius: $border-radius;
        padding: 0.7rem;
      }

    }
    @include media-breakpoint-down(xs) {
      a{
        padding: 0.4rem;
      }
    }
  }
}

.c-menu__link {
  padding: 0 .5rem;
  transition: color $transition-default;

  &:hover {
    text-decoration: none;
  }
}

.c-menu__link--active {
  color: var(--color-primary);

  &:hover {
    color: var(--color-dark);
  }
}

.c-menu__submenu{
  list-style: none;
  padding: 0.5rem;
}

.c-menu__subitem{
  font-size: 0.88rem;
  line-height: 1.77rem;
}
/* =======================
 * Sub menu
========================== */

.c-menu--sub{
  border-bottom: 1px solid var(--color-grey-3);

  a{
    font-size: 0.72rem;
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
      font-size: 1rem;

    }

    @include media-breakpoint-down(xs) {
      font-size: 0.77rem;
    }
  }
  @include media-breakpoint-down(md) {
    border-bottom: none;
  }
}

.c-menu__dropdown-menu{
  display: none;
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-light);
  padding: 0.9rem 1.5rem;
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
  border-radius: $border-radius;
  z-index: 2;
  width: 230px;

  list-style-type: none;

  li{
    line-height: 2rem;
  }
  a{
    text-transform: capitalize;
    font-size: 0.875rem;
  }

  @include media-breakpoint-down(md) {
    display: block;
    left: 0.5rem;
    top: 0.5rem;
    box-shadow: unset;
    background-color: transparent;
    transform: translateX(0);
    position: relative;

    a{
      text-transform: uppercase;
      font-size: 1rem;
    }
  }
  @include media-breakpoint-down(xs) {
    a{
      font-size: 0.77rem;
    }
  }
}

.c-menu__item{
  &:hover{
    .c-menu__dropdown-menu{
      display: block;
    }
  }
}

.menu-item-has-children{
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.darken-on-hover{
  &:hover {
    background: var(--color-grey-3);
  }
}


/* =======================
 * Main menu
========================== */
.c-menu--main{
  .c-menu__item{
    font-weight: 600;
  }
}


/* =======================
 * Footer menu
========================== */
.c-menu--footer {
  width: 100%;
  .c-menu__container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-between;
    height: auto;

    @include media-breakpoint-down(sm) {
      justify-content: flex-start;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }
  .c-menu__item {
    flex-grow: 1;
    display: block;
    @include media-breakpoint-down(sm) {
      width: 50%;
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }
  .c-menu__link{
    color: var(--color-dark);
    font-weight: bolder;
    &:hover{
      text-decoration: underline;
    }
  }

  .darken-on-hover{
    &:hover {
      background: transparent;
    }
  }
}



/* =======================
 * Disclaimer menu
========================== */
.c-menu--disclaimer {
  .c-menu__item{
    padding: 0;
    @include media-breakpoint-down(md) {
      margin: 0.1rem 0;
    }
  }
  .c-menu__link {
    padding-left: 0;
    font-size: 0.77rem;
    line-height: 1rem;
    &:before{
      content: '•';
      position: relative;
      padding-right: 5px;
      color: var(--color-dark);
      font-size: 0.7rem;
    }

    @include media-breakpoint-down(md) {
      &:before{
        content: '';
        padding: 0;
      }
    }
  }
}



/* =======================
 * Partner menu
========================== */
.c-menu--partner {
  .c-menu__link {
    font-size: 0.9rem;
  }

  .c-menu__container{
    display: flex;
  }
}
.c-menu-item--dark a{
  color:var(--color-dark);
}
.c-menu-item--primary a{
  color:var(--color-primary);
}
.c-menu-item--secondary a{
  color:var(--color-secondary);
}
.c-menu-item--tertiary a{
  color:var(--color-tertiary);
}
