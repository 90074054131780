.theme-index-search-bar {

  background-color: var(--color-primary-translucent);

  &.custom-bg-color {
    background-color: initial;
  }
}

.theme-index-title {
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  display: block;
}

.theme-index-search-bar-filter {

  display: flex;

  li {
    display: inline-block;
  }

  @include media-breakpoint-down(md) {
    overflow-x: scroll;
  }
}

.theme-index-search-bar-filter {

}

.theme-index-search-bar-filter {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;

  li:first-child .theme-index-search-bar-letter-filter {
    margin-left: 0;
  }
}

.theme-index-search-input {
  max-width: 21rem;
}

.theme-index-search-bar-letter-filter {
  border-radius: $border-radius;
  margin: .275rem;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-primary);
  border: 1px solid currentColor;
  transition: none;
  font-size: .7778rem;

  &:hover, &:focus {
    text-decoration: none;
    outline: none;
  }

  &.empty-section {
    opacity: .25;
    cursor: default;
  }

}

.theme-index-letter-section {
  h3 {
    font-size: 1.75rem;
  }

  padding: 1.333rem 0;

  &:first-child {
    padding-top: 0;
  }

  &.empty-section {
    padding: 0;
    color: hsla(60, 2%, 11%, 0.4);
  }
}

.theme-index-letter-section-list {
  -moz-column-count: 1;
  -moz-column-gap: 20px;
  -webkit-column-count: 1;
  -webkit-column-gap: 20px;
  column-count: 1;
  column-gap: 20px;
  margin: 0;
  @include media-breakpoint-up(md) {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
  @include media-breakpoint-up(lg) {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }

}

.theme-index-letter-section-list-item {
  padding: 0.5rem 0;

  a {
    color: var(--color-primary);
    display: inline-block;
    overflow-wrap: anywhere;
  }
}

.theme-index-highlighted-section {
  border-radius: $border-radius;
  background-color: var(--color-primary);
  padding: 1.75rem;
  max-width: 20rem;
  color: white;
  margin-bottom: 1.75rem;

  h4 {
    font-size: 1.75rem;
    font-weight: normal;
  }

}

.theme-index-highlighted-list {
  margin-top: 1.75rem;

}

.theme-index-highlighted-list-item {
  margin: .5rem 0 0;

  a {
    color: white;
    text-decoration: none;
  }
}
