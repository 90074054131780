
.c-item-row{
  height: 84px;
  padding: 1rem;
  border-radius: 4px;
  background-color: var(--color-light);
  box-shadow: var(--shadow-lighter);
  position: relative;
  overflow: hidden;
  padding-right: 1.5rem;

  &:hover{
    transform: scale(1.05);
    transition: $transition-default;
    a{
      text-decoration: none;
    }
  }

  svg{
    height: 100%;
  }

}

.c-item-row__grid-tile{
  padding: 1rem 1.2rem;
  border-radius: 4px;
  background-color: var(--color-tertiary-48);
  height: 120px;
  box-shadow: var(--shadow-lighter);
  &:hover{
    transform: scale(1.05);
    transition: $transition-default;
    a{
      text-decoration: none;
    }
  }
}


.c-item-row__grid-tile-icon{
  color: var(--color-primary);
  font-size: 2rem;
  text-align: left;
  @include media-breakpoint-down(md) {
    font-size: 1.5rem;
  }


}


.c-item-row__title{
  font-size: 1rem;
  margin-bottom: 0;
}

.c-item-row__subtitle{
  font-size: 0.72rem;
}

.c-item-row__company{
  font-size: 11px;
  text-transform: uppercase;
  color: var(--color-primary);
  font-weight: bold;
}


.c-item-row__date{
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 100%;
  background-color: rgba(0,123,142,0.2);
  //background-color: var(--color-primary-30);
  color: var(--color-primary);
}

.c-item-row__date-day{
  font-size: 12px;
}
.c-item-row__date-date{
  font-weight: 600;
  font-size: 1.33rem;
}


.c-item-row__content{
  margin-left: 80px;
}

.c-item-row__arrow{
  flex-shrink: 0;
}
